import React, { useEffect } from "react";
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useTranslation } from "react-i18next"
import Layout from "../layout/layout"
import { graphql } from "gatsby";
import Seo from "../components/seoComponent";
import Container from '../components/Container';
import { ButtonA } from "../components/Button/ButtonA";
import { myProjects } from "../data/myProjects"
import { websiteUrl } from "../data/navItems"

const StyledProjectsPage = styled.section`
    padding: var(--firstSectionPadding);
`;

const Project = styled.div`
    --borderRadius: 16px;
    display: grid;
        grid-template-columns: 60% 40%;
    padding: calc(var(--borderRadius) * 2) 2rem;
    background: #f7f7f7;
    color: var(--textNormal);
    border: 1px solid #eaeaea;
        border-radius: var(--borderRadius);
    opacity: 0;
    position: relative;
    
    &:hover::after {
        opacity: 1;
    }
    
    &::after {
        position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        opacity: 0;
        box-shadow: 0 5px 45px -30px var(--primary);
        transition: .3s ease-out opacity;
        will-change: opacity;
        border-radius: var(--borderRadius);
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
    
    .img_container {
        display: grid;
        place-items: center;
        
        .gatsby-image-wrapper {
            height: auto;
            width: 100%;
            border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
        }
    }
    
    h2 {
        margin: 0;
    }
    
    .text_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //padding: calc(var(--borderRadius) * 2) 1em calc(var(--borderRadius) * 2) 0em;
        grid-gap: 16px;
        
        .technologies_wrapper {
            display: grid;
                grid-template-columns: repeat(auto-fit, minmax(5rem, 7rem));
                grid-gap: .5rem;
        }
        
        p {
            margin: 0;
        }
    }
    
    .see_project {
        display: grid;
            grid-template-columns: auto 1fr;
            align-items: baseline;
        margin-top: 1rem;
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        
        .text_wrapper {
            display: grid;
            padding: var(--borderRadius);
            grid-gap: 16px;
            order: 2;
        }
        
        .see_project {
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 8px;
            
        }
    }
`;

const PortfolioPage = ({ location }) => {

    const url = `${websiteUrl + location.pathname }`;
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    useEffect(() => {
        // gsap.fromTo('.project', { x: 100, autoAlpha: 0 }, { x: 0, autoAlpha: 1, stagger: .2, ease: 'power4.out', duration: 1.5 });
        gsap.set(['.project'], { y: 50, autoAlpha: 0, ease: 'power4.out', duration: .5 });

        let startAndEnd = '0% 90%';
        ScrollTrigger.batch(".project", {
            trigger: '.project',
            start: startAndEnd,
            end: startAndEnd,
            onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.2 }),
            onEnterBack: batch => gsap.to(batch, { y: 50, autoAlpha: 0, stagger: 0.2 }),
        });
    }, []);

    return (
        <Layout>
            <StyledProjectsPage>
                <Seo
                    title="Projekty"
                    description="Sprawdź wybrane projekty, które zrealizowałem."
                    pageUrl={url}
                    pageName={location.pathname.slice(1,-1)}
                />
                <Container>
                    {myProjects.map(project => (
                        <Project key={project.name} className="project">
                            <div className="text_wrapper">
                                <h2>{project.name}</h2>
                                <p>
                                    {locale === 'pl' && project.descriptionPL}
                                    {locale === 'en' && project.descriptionEN}
                                </p>
                                <div className="technologies_wrapper">{project.technologies}</div>
                                <div className="see_project">
                                    <ButtonA filled href={project.link}>
                                        {t('utils.see_project')}
                                    </ButtonA>
                                </div>
                            </div>
                            <div className="img_container">
                                {project.image}
                            </div>
                        </Project>
                    ))}
                </Container>
            </StyledProjectsPage>
        </Layout>
    )};

export const query = graphql`
    query {
        image: file(name: {eq: "form_start"}) {
            childImageSharp {
                resize(width: 1200) {
                    src
                    height
                    width
                }
            }
        }
    }
`;

export default PortfolioPage;
