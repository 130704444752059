import { Tag } from "../components/Tag"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export const myProjects = [
    {
        name: 'MowaDruku',
        descriptionPL: 'Strona marketingowa dla aplikacji mobilnej połączona z systemem CMS, wykorzystująca grafikę 3D.',
        descriptionEN: 'A marketing website for a mobile application connected to the CMS system, using 3D graphics.',
        technologies: [<><Tag>#Gatsby</Tag><Tag>#DatoCMS</Tag><Tag>#Three.js</Tag></>],
        image: <StaticImage
            src="../../static/projects_mockups/mowadruku_mockup.png"
            width="400"
            alt=""
            formats={["auto", "webp", "avif"]}
        />,
        link: 'https://www.mowadruku.pl'
    },
    {
        name: 'Telkat',
        descriptionPL: 'Strona internetowa dla serwisu telefonów komórkowych z wykorzystaniem starego projektu graficznego. Jej głównym zadaniem jest pozyskiwanie nowych klientów za pomocą formularza.',
        descriptionEN: 'Website for mobile phone service with an previous graphic layout. Its main task is to acquire new customers using the contact form.',
        technologies: [<><Tag>#Gatsby</Tag><Tag>#Airtable</Tag><Tag>#SendGrid</Tag></>],
        image: <StaticImage
            src="../../static/projects_mockups/telkat_mockup.png"
            width="400"
            alt=""
            formats={["auto", "webp", "avif"]}
        />,
        link: 'https://new.telkat.pl'
    },
];